<!-- 订单详情 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="handGoBack" />
      <div class="header-content">订单详情</div>
    </header>
    <section class="page-info">
      <div v-if="fetchLoading">
        <div class="delivery-info bill-creater-info">
          <div class="delivery-title" stylw="width:100%;">
            配送信息：
          </div>
          <div class="delivery-content">
            {{ order_info.province }} {{ order_info.city }} {{ order_info.county }} {{ order_info.address }}
          </div>
          <div class="delivery-content">
            {{ order_info.organ_name }} {{ order_info.branch_name }} 病房:{{ order_info.room_number }}
            病床:{{ order_info.bed_number }}
          </div>
          <div class="delivery-people">
            {{ order_info.consignee }} {{ order_info.phone_num }}
          </div>
        </div>
        <div class="bill-creater-info">
          <div class="bill-time" style="width:100%">
            订单编号：{{ order_info.order_sn }}
          </div>
          <div class="bill-time">
            订餐时间：{{ getTimeYMDHMS(order_info.insert_dt) }}
          </div>
          <div style="color:#FF3333">
            ￥{{ order_info.amount }}
          </div>

          <div v-if="order_info.state == 1 && order_info.pay_state == 1 && order_info.refund_state != 2"
            style="color:#1989fa;width:100%">
            订单状态：{{ order_info.delivery_state_str }}
          </div>
          <div v-else-if="order_info.state != 1" style="width:100%">
            订单状态：已取消
          </div>
          <div v-else-if="order_info.refund_state == 2" style="color:#FF3333;width:100%">
            订单状态：已退款（原路退回）
          </div>
          <div v-else-if="order_info.state == 1 && order_info.pay_state == 0 && order_info.delivery_state == 0"
            style="color:#FF3333;width:100%">
            订单状态：{{ order_info.pay_state_str }}
          </div>
          <div v-else style="color:#FF3333;width:100%">
            订单状态：{{ order_info.pay_state_str }}
          </div>

          <div v-if="order_info.state == 1 && order_info.delivery_state == 0 && order_info.refund_state <= 0"
            style="width:100%;margin-top:5px">
            <van-button type="warning" plain hairline round size="small" :loading="is_loading"
              @click="handleCancelOrder(order_info.order_sn)">
              {{ order_info.pay_state == 1 ? '订单退款' : '取消订单' }}
            </van-button>
          </div>
        </div>
        <div class="sign-btn">
          <div
            v-if="order_info.state == 1 && order_info.delivery_state == 1 && order_info.pay_state > 0 && order_info.refund_state != 2"
            style="width:50%;">
            <van-button type="danger" round block size="small" :loading="sign_loading"
              @click="handleSignOrder(order_info.order_sn)">
              确认签收
            </van-button>
          </div>
        </div>
        <div class="bill-detail">
          <section v-if="order_info.shop_type == 3">
            <div v-if="order_info.dishes_list.length > 0" style="padding-bottom: 70px;">
              <div class="meal-type">
                <div>餐食列表</div>
              </div>
              <div class="bish-main">
                <div v-for="(d_item, d_index) in order_info.dishes_list" :key="d_index">
                  <div class="bish-detail">
                    <van-row gutter="10">
                      <van-col span="12">
                        <div class="dish-name">
                          {{ d_item.dishes_name }}
                        </div>
                      </van-col>
                      <van-col span="6">
                        <div class="dish-num">
                          x{{ d_item.dishes_num }}
                        </div>
                      </van-col>
                      <van-col span="6">
                        <div class="dish-price">
                          ￥{{ d_item.pay_price }}
                        </div>
                      </van-col>
                    </van-row>
                    <div class="mode-main">
                      <div v-for="(modeID, modeIndex) in modeObj['meal_' + d_item.dishes_id].mode_ids" :key="modeIndex"
                        class="mode-item">
                        <div class="mode-tit">{{ modeObj['meal_' + d_item.dishes_id + '_mode_' + modeID].mode_name
                          }}</div>
                        <div class="dishes-main">
                          <span
                            v-for="(modeDishesName, modeDishesIndex) in modeObj['meal_' + d_item.dishes_id + '_mode_' + modeID].dishes_list"
                            :key="modeDishesIndex">{{ modeDishesIndex == 0 ? modeDishesName : "、" + modeDishesName
                            }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section v-else>
            <div v-if="goods_list.length > 0">
              <div v-for="(item, index) in goods_list" :key="index">
                <div class="meal-type">
                  <div v-if="item.meal_mode == 1">早餐</div>
                  <div v-else-if="item.meal_mode == 2">午餐</div>
                  <div v-else-if="item.meal_mode == 3">晚餐</div>
                  <div v-else>其他</div>
                </div>
                <div v-for="(d_item, d_index) in item.dishes_list" :key="d_index">
                  <div class="bish-detail">
                    <van-row gutter="10">
                      <van-col span="12">
                        <div class="dish-name">
                          {{ d_item.dishes_name }}
                        </div>
                      </van-col>
                      <van-col span="6">
                        <div class="dish-num">
                          x{{ d_item.dishes_num }}
                        </div>
                      </van-col>
                      <van-col span="6">
                        <div class="dish-price">
                          ￥{{ d_item.pay_price }}
                        </div>
                      </van-col>
                    </van-row>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div v-if="order_info.state == 1 && order_info.pay_state == 0" class="summit">
          <van-submit-bar :price="order_info.amount * 100" button-text="支付订单" :loading="submitLoading"
            @submit="onSubmit">
          </van-submit-bar>
        </div>
      </div>
      <div v-else>
        <div style="margin-top:10vh">
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast, Dialog } from 'vant'

import { getTimeYMD, getTimeYMDHMS } from "@/utils/util"
import CommonData from "../../utils/commonData"
import { getStorage, setStorage } from "../../utils/localStorageUtils"
import order from "@/api/ofs/order"
import wxPay from "@/utils/wxPay"
import mywx from "@/api/ofs/wx"
import { isEmptyObject, isEmpty } from "@/utils/util";

export default {
  name: "bill-info",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      if (options != null && options.order_sn != null) {
        data.order_sn = options.order_sn
      }
      orderInfo()
    });

    const data = reactive({
      order_sn: '',
      order_info: {},
      goods_list: [],
      submitLoading: false,
      is_loading: false,
      sign_loading: false,
      fetchLoading: false,
      modeObj: {},
    });

    const orderInfo = () => {
      const post_data = {
        order_sn: data.order_sn
      }
      var goodsList = [];
      var goodsObj = {};
      data.fetchLoading = false
      order.getOrderInfo(post_data).then(res => {
        console.log('getOrderInfo', res)
        data.order_info = res.data
        if (data.order_info.dishes_list != null && data.order_info.dishes_list.length > 0) {
          data.order_info.dishes_list.map(item => {
            item.member_price = (parseFloat(item.member_price)).toFixed(2)
            var key = 'mode_' + item.meal_mode;
            if (!Object.keys(goodsObj).includes(key)) {
              goodsObj[key] = {
                meal_mode: item.meal_mode,
                dishes_list: []
              };
            }
            goodsObj[key].dishes_list.push(item);
          })
        }

        data.goodsList = Object.keys(goodsObj).map((key) => goodsObj[key]);;
        console.log('goodsList', data.goodsList)

        var modeObj = {};
        if (parseInt(data.order_info.shop_type) == 3) {
          for (var i = 0; i < res.data.mode_list.length; i++) {
            var modeInfo = res.data.mode_list[i];
            var mealKey = 'meal_' + modeInfo.meal_id;
            if (!Object.keys(modeObj).includes(mealKey)) {
              modeObj[mealKey] = {
                mode_ids: [],
              };
            }

            var modeKey = mealKey + '_mode_' + modeInfo.meal_mode;
            if (!Object.keys(modeObj).includes(modeKey)) {
              modeObj[mealKey].mode_ids.push(modeInfo.meal_mode);
              modeObj[modeKey] = {
                meal_mode: modeInfo.meal_mode,
                mode_name: modeInfo.mode_name,
                dishes_list: []
              }
            }
            modeObj[modeKey].dishes_list.push(modeInfo.dishes_name);
          }
        }
        data.modeObj = modeObj;

        data.fetchLoading = true
      }).catch(err => {
        data.fetchLoading = true
      })
    }
    const handGoBack = () => {
      $router.push({
        name: "bill"
      })
    }

    //调起支付
    const wexinPay = (params) => {
      wxPay(params).then(res => {
        Toast({ type: 'success', message: '支付成功' });
        data.submitLoading = false
        orderInfo()
      }).catch(err => {
        Toast({ type: 'fail', message: '支付失败，请重试' });
        data.submitLoading = false
      })
    };

    const onSubmit = () => {
      console.log("提交订单!")
      Toast.loading({
        message: '订单提交中...',
        forbidClick: true,
        duration: 0
      });
      data.submitLoading = true
      const post_data = {
        out_trade_sn: data.order_info.order_sn,
        out_trade_type: (data.order_info.shop_type == 1 || data.order_info.shop_type == 3) ? 102 : 103,
        description: "线上支付",
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID)
      }
      mywx.payOfsOrder(post_data).then(res => {
        if (res.code == 0) {
          // const pay_params = res.data;
          // wexinPay(pay_params);
          window.location.href = res.data.pay_url
          Toast.clear()
          data.submitLoading = false
        } else {
          proxy.$toast({ type: "fail", message: res.message });
        }
      }).catch(err => {
        Toast.clear()
        data.submitLoading = false
      })
    }

    const handleCancelOrder = sn => {
      Dialog({
        message:
          data.order_info.pay_state == 1 ? '确定取消订单,并申请退款？' : '确定取消订单？',
        showCancelButton: true,
      })
        .then(() => {
          data.is_loading = true
          const post_data = {
            module_id: (data.order_info.shop_type == 1 || data.order_info.shop_type == 3) ? 102 : 103,
            module_sn: data.order_info.order_sn,
            refund: (data.order_info.amount > 0 ? parseInt(data.order_info.amount * 100) : 0),
            reason: "取消订单",
            canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID)
          }
          order.wxCancelOrder(post_data).then(res => {
            Toast('操作成功');
            data.is_loading = false
            orderInfo()

          }).catch(err => {
            Toast(err.message);
            data.is_loading = false
          })

        })
        .catch(() => {
          // on cancel
        });
    }

    const handleSignOrder = sn => {
      Dialog({
        message: '确定收到餐食，并签收？',
        showCancelButton: true,
      })
        .then(() => {
          data.sign_loading = true
          const post_data = {
            order_sn: sn
          }
          order.signOrder(post_data).then(res => {
            Toast('操作成功');
            data.sign_loading = false
            orderInfo()

          }).catch(err => {
            Toast(err.message);
            data.sign_loading = false
          })

        })
        .catch(() => {
          // on cancel
        });
    }

    return {
      ...toRefs(data),
      getTimeYMD,
      getTimeYMDHMS,
      handGoBack,
      onSubmit,
      handleCancelOrder,
      handleSignOrder
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;

  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;

    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }

    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }

  .page-info {
    background-color: #fff;
    min-height: 300px;
    margin: 15px;

    .delivery-info {
      background: #fafafa;
      border-radius: 12px;
      font-size: 14px;
      line-height: 25px;
      padding: 10px;
      color: #666666;

      .delivery-title {
        font-weight: 800;
        color: #FF3333;
      }

      .delivery-content {
        color: #000000;
        line-height: 20px;
      }

      .delivery-people {
        color: #000000;
        font-size: 12px;
      }
    }

    .bill-creater-info {
      margin: 15px 0;
      border-radius: 20px;
      background: #fafafa;
      padding: 15px;
      font-size: 14px;
      line-height: 24px;
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .sign-btn {
      width: 100%;
      margin: 15px 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: right;
    }

    .bill-detail {
      .meal-type {
        font-size: 18px;
        color: #666666;
        margin: 10px 0;
      }

      .bish-main {
        .bish-detail {
          border-top: 1px solid #e5e5e5;
          padding-top: 10px;
          margin: 15px 0;

          line-height: 20px;
          color: #AAAAAA;

          .dish-name {
            font-size: 15px;
            color: #000000;
          }

          .dish-num {
            font-size: 15px;
            text-align: center;
          }

          .dish-price {
            font-size: 15px;
            text-align: center;
          }

          .mode-main {
            margin-top: 10px;

            .mode-item {
              margin-top: 10px;
              font-size: 14px;

              .mode-tit {
                color: #42a5f5;
              }

              .dishes-main {
                margin-top: 5px;
                color: #666666;
              }
            }
          }
        }
      }


      .bill-evaluate {
        margin: 15px 0;
        width: 100%;
        text-align: right;
      }
    }
  }
}
</style>
